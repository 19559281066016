.App {
    text-align: center;
}

body {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0
}
